import './UWCBProjects.css';

function UWCBProjects() {

  return (
    <div className="UWCBProjects">
    </div>
  );
}

export default UWCBProjects;