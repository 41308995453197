import './Intro.css';

function Intro() {
  return (
    <div className="Intro">
      <h1 id="title-name"><span class="span" id='one'>C</span><span class="span" id='two'>arsdan</span><span class="span" id='three'>D</span><span class="span" id='four'>vorachek</span></h1>
    </div>
  );
}

export default Intro;